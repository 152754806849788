import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2ViZGV2Ym9pIiwiYSI6ImNqcWlqbDQ3aTA3cjIzeW90Z3hxbXhseWMifQ.0NFz5xsRYXawnU0cbFGvQQ';

export default () => {

  const element = document.querySelector('#map');
  if (element === null) return;
  const lat = parseFloat(element.getAttribute('data-lat'));
  const lng = parseFloat(element.getAttribute('data-lng'));

  const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v9',
    zoom: 13,
    center: [ // mapbox puts lng then lat
      // -4.259816,
      // 54.620976,
      lng,
      lat,
    ],
    scrollZoom: false,
  });

  const marker = new mapboxgl.Marker()
    .setLngLat([lng, lat])
    .addTo(map);

 

}
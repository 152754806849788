export const debounce = (fn, duration) => {
  let timeout;
  return (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(e), duration);
  }
}

export const domBuilder = (type, props, ...children) => {
  const el = document.createElement(type);

  for (let key in props) {
    if (key === 'dangerouslySetInnerHTML') {
      el.innerHTML = props[key];
    } else {
      const realKey = key === 'className' ? 'class' : key;
      if (props[key] !== false) {
        el.setAttribute(realKey, props[key]);
      }
    }
  };

  if (Array.isArray(children)) {
    children.forEach((child) => {
      if (typeof child === 'string' || typeof child === 'number') {
        el.appendChild(document.createTextNode(child));
      } else {
        if (Array.isArray(child)) {
          child.forEach((e) => {
            el.appendChild(e);
          });
        } else if(child != null && child.nodeType != null){
          el.appendChild(child);
        } else {
          console.warn(`domBuilder: value of child was ${child} and its type ${typeof child}`);
        }
      }
    });
  }

  return el;
};

// data-smooth-anchor
import { debounce } from './utils';

export default () => {
  let headerHeight = 0;
  const header = document.querySelector('.header');

  const setHeaderHeight = () => {
    headerHeight = header.getBoundingClientRect().height;
  }

  document.addEventListener('click', (e) => {
    const smoothAnchor = e.target.closest('[data-smooth-anchor]');
    if (smoothAnchor === null) return;
    const { hash } = new URL(smoothAnchor.href);
    const targetToScrollTo = document.querySelector(hash);
    if (targetToScrollTo === null) return;
    e.preventDefault();
    const { top } = targetToScrollTo.getBoundingClientRect();
    const headerOffset = headerHeight;
    const scrollPosition = top + window.pageYOffset - headerOffset;
    const newURL = new URL(window.location);
    newURL.hash = hash;

    window.history.pushState({}, null, newURL.href);

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    })
  });

  setHeaderHeight();

  window.addEventListener('resize', debounce(setHeaderHeight, 100));
}
export default () => {
  const submitButtonsToReplace = Array.from(document.querySelectorAll('.wpforms-submit'));
  const buttonTemplate = document.querySelector('.button-template .button');

  submitButtonsToReplace.forEach((button) => {
    const submitButton = document.createElement('button');
    submitButton.classList.add('button');
    submitButton.type = 'submit';
    submitButton.innerHTML = buttonTemplate.innerHTML.replace('TEXT', 'Send message');
    button.parentElement.appendChild(submitButton);
  });
  
}
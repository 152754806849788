import { domBuilder } from './utils';

export default (menuElement) => {

  let subMenuID = 0;
  /**
   * Generates an array that will then be used to create DOM elements
   * 
   * @param {HTMLElement} ulElement A ul element containing links
   * @returns {any[]} An array of the menu structure
   */
  const getPanelConfig = (ulElement, array = [], parentText = null) => {
    const arrayItem = Array
      .from(ulElement.children)
      .map((liElement) => {
        const aElement = liElement.querySelector('a');
        const { href, textContent } = aElement;
        const subMenu = liElement.querySelector('ul');

        const obj = {
          href,
          textContent,
          parentText,
          parentIndex: subMenuID - 1,
          childIndex: null,
        };

        if (subMenu !== null) {
          subMenuID++;
          obj.childIndex = subMenuID;
          getPanelConfig(subMenu, array, textContent);
        }

        return obj;
      });

    array.push(arrayItem);
    /**
     * Want to reverse the array as due to function being recursive the config
     * is in reverse order.
     */
    if (parentText === null) return array.reverse();
  };

  /**
   * Generates the DOM nodes for the mobile menus panels
   * 
   * @param {any[]} tree - The panel config to use to create the DOM nodes
   * @returns {HTMLElement[]} An array of panel DOM nodes
   */
  const createPanels = (tree) => {
    return tree.map((links) => {
      const { parentText, parentIndex } = links[0];

      const title = parentIndex === -1
        ? null
        : domBuilder(
            'p', {}, 
            domBuilder(
              'button',
              { 'data-parent-index': parentIndex, class: 'mobile-menu__back' },
              'Back',
            ),
            parentText,
          );

      return domBuilder(
        'div',
        { class: 'mobile-menu__panel' },
        title,
        domBuilder('ul', {}, links.map((link) => {
          const { href, textContent, childIndex } = link;
          const props = { href, class: 'mobile-menu__link' };

          if (childIndex !== null) props['data-child-index'] = childIndex;
          return domBuilder('li', {}, domBuilder('a', props, textContent));
        })),
        domBuilder('button', { class: 'mobile-menu__close' }, 'Close'),
      );

    });
  };

  const panelConfig = getPanelConfig(menuElement);
  const panels = createPanels(panelConfig);
  const backgroundEl = domBuilder('div', { class: 'mobile-menu__background' });
  const mobileMenu = domBuilder(
    'nav',
    { class: 'mobile-menu' },
    ...panels,
    backgroundEl,
  );
  const panelTransitionClassNames = {
    enter: 'mobile-menu__panel--t-enter',
    leaveTo: 'mobile-menu__panel--t-leave-to',
    enterActive: 'mobile-menu__panel--t-enter-active',
    leaveToActive: 'mobile-menu__panel--t-leave-to-active',
  };
  const backgroundTransitionClassName = 'mobile-menu__background--t-active';

  let activePanelIndex = 0;
  let changingActivePanel = false;
  panels[activePanelIndex].style.display = 'block';

  const toggle = (force = null) => {
    if (force === null) return mobileMenu.classList.toggle('mobile-menu--open');
    mobileMenu.classList.toggle('mobile-menu--open', force);
  }

  const changeActivePanel = (index) => {
    if (changingActivePanel) return;
    changingActivePanel = true;

    const currentPanel = panels[activePanelIndex];
    const newPanel = panels[index];
    const { height } = currentPanel.getBoundingClientRect();

    currentPanel.classList.add(panelTransitionClassNames.leaveToActive);
    newPanel.classList.add(panelTransitionClassNames.enterActive);

    // Don't want to wait for transition for enter styles to be applied
    newPanel.classList.add(panelTransitionClassNames.enter);
    newPanel.style.display = 'block';

    const { height: newPanelHeight } = newPanel.getBoundingClientRect();
    const transformScale = Math.min(newPanelHeight, height) / Math.max(newPanelHeight, height);

    /**
     * Set height to max panel height and then use a transform to scale to
     * correct dimensions
     */
    backgroundEl.style.height = `${Math.max(height, newPanelHeight)}px`;

    if (newPanelHeight > height) {
      backgroundEl.style.transform = `scaleY(${transformScale})`;
    }

    const currentPanelTransition = ({ target }) => {
      if (target !== currentPanel) return;
      currentPanel.style.display = '';
      currentPanel.classList.remove(panelTransitionClassNames.leaveTo);
      currentPanel.classList.remove(panelTransitionClassNames.leaveToActive);
      currentPanel.removeEventListener('transitionend', currentPanelTransition);

      if (newPanelHeight > height) {
        backgroundEl.style.transform = '';
      } else {
        backgroundEl.style.transform = `scaleY(${transformScale})`;
      }
    
    }

    const backgroundTransition = ({ target }) => {
      if (target !== backgroundEl) return;
      backgroundEl.removeEventListener('transitionend', backgroundTransition);
      newPanel.classList.remove(panelTransitionClassNames.enter);
    }

    const newPanelTransition = ({ target }) => {
      if (target !== newPanel) return;
      newPanel.removeEventListener('transitionend', newPanelTransition);
      newPanel.classList.remove(panelTransitionClassNames.enterActive);
      backgroundEl.style.transform = '';
      backgroundEl.style.height = '';
      backgroundEl.classList.remove(backgroundTransitionClassName);
      changingActivePanel = false;
      activePanelIndex = index;
    }

    currentPanel.addEventListener('transitionend', currentPanelTransition);
    backgroundEl.addEventListener('transitionend', backgroundTransition);
    newPanel.addEventListener('transitionend', newPanelTransition);

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        currentPanel.classList.add(panelTransitionClassNames.leaveTo);
        backgroundEl.classList.add(backgroundTransitionClassName);
      });
    });
    
  }

  mobileMenu.addEventListener('click', (e) => {
    const parentClick = e.target.closest('[data-child-index]');
    const backClick = e.target.closest('[data-parent-index]');
    const closeClick = e.target.closest('.mobile-menu__close');
    if (parentClick === null && backClick === null && closeClick === null) return;
    
    if (parentClick !== null) {
      e.preventDefault();
      const index = parseInt(parentClick.getAttribute('data-child-index'), 10);
      return changeActivePanel(index);
    }

    if (backClick !== null) {
      const index = parseInt(backClick.getAttribute('data-parent-index'), 10);
      return changeActivePanel(index);
    }

    // Means the close button was clicked at this point
    toggle(false);
  });

  return {
    toggle,
    el: mobileMenu,
  };
}
export default () => {
  const headerMenu = document.querySelector('.header__menu');
  const dropdowns = Array.from(headerMenu.querySelectorAll('.menu__sub-menu'));

  dropdowns.forEach((el) => {
    el.style.visibility = 'visible';
    el.setAttribute('aria-hidden', true);
  });

  headerMenu.addEventListener('transitionend', (e) => {
    const { target, propertyName } = e;
    if (! (propertyName === 'opacity' && target.matches('.menu__sub-menu'))) return;
    const { opacity } = window.getComputedStyle(target);
    if (opacity == 1) {
      target.removeAttribute('aria-hidden');
    } else {
      target.setAttribute('aria-hidden', true);
    }
  })

  // headerMenu.addEventListener('mouseenter', (e) => {
  //   const parentMenuItem = e.target.closest('.menu__item--has-children');
  //   if (parentMenuItem === null) return;
  //   const subMenu = parentMenuItem.querySelector('.menu__sub-menu');
  //   subMenu.removeAttribute('aria-hidden');
  // }, true);

  // headerMenu.addEventListener('mouseleave', (e) => {
  //   const parentMenuItem = e.target.closest('.menu__item--has-children');
  //   if (parentMenuItem === null) return;
  //   const subMenu = parentMenuItem.querySelector('.menu__sub-menu');
  //   subMenu.setAttribute('aria-hidden', true);
  // }, true);
}
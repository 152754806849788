/**
 * Handles changing the header background after the user has scrolled
 */
export default () => {

  // @see https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
  // Test via a getter in the options object to see if the passive property is accessed
  let supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: () => supportsPassive = true,
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {}

  const header = document.querySelector('.header');
  const className = 'header--scrolled';

  const rafThrottle = (fn) => {
    let isRafQueued = false;

    return (...args) => {
      if (isRafQueued) return;
      requestAnimationFrame(() => {
        fn(...args);
        isRafQueued = false;
      });
    }
  };

  const run = () => {
    if (window.pageYOffset > 0) return header.classList.add(className);
    header.classList.remove(className);
  }

  run();

  window.addEventListener(
    'scroll',
    rafThrottle(run),
    supportsPassive ? { passive: true } : false,
  );

}
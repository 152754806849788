export default () => {
  const faqs = document.querySelector('.faqs');
  if (faqs === null) return;

  faqs.addEventListener('click', (e) => {
    const button = e.target.closest('.faq__button');
    if (button === null) return;
    const faq = button.closest('.faq');
    const isOpen = faq.classList.contains('faq--open');
    faq.classList.toggle('faq--open');

    if (isOpen) {
      faq.removeAttribute('aria-expanded');
      faq.setAttribute('aria-hidden', 'true');
    } else {
      faq.removeAttribute('aria-hidden');
      faq.setAttribute('aria-expanded', 'true');
    }
  });
}
import svg4everybody from 'svg4everybody';
import headerBackground from './headerBackground';
import smoothAnchor from './smoothAnchor';
import headerDropdowns from './headerDropdowns';
import mobileMenu from './mobileMenu';
import form from './form';
import map from './map';
import faq from './faq';

const headerWrapper = document.querySelector('.header__wrapper');
const headerToggle = headerWrapper.querySelector('.header__toggle');

headerBackground();
smoothAnchor();
headerDropdowns();
form();
map();
faq();

const { el, toggle } = mobileMenu(document.querySelector('.header__menu ul'));

headerToggle.addEventListener('click', () => toggle());


headerWrapper.appendChild(el);

svg4everybody();